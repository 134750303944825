import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
} from "@mui/material";
import { Box } from "@material-ui/core";
import InsertInvitationTwoToneIcon from "@mui/icons-material/InsertInvitationTwoTone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { keyframes } from "@mui/system";
import { styled } from "@mui/material/styles";
import MoveReservationDialog from "../../components/Dialog/MoveReservationDialog";
import RejectReservationDialog from "../../components/Dialog/RejectReservationDialog";
import moment from "moment/moment";
import EditIcon from "@mui/icons-material/Edit";
import EditReservationDataDialog from "../Dialog/EditReservationData";
import { URL, authAxios } from "../../NetworkRequest";
import ReserveConsultationDialog from "../Dialog/ReserveConsultationDialog";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BlinkedBox = styled("div")({
  backgroundColor: "red",
  width: 12,
  height: 12,
  borderRadius: 6,
  animation: `${blink} 1s ease infinite`,
});

const ReservationStatus = ({
  done,
  cancel,
  doneDate,
  cancelDate,
  index,
  setOpenReject,
  onConfirm,
  onDone,
  confirmed,
  didntCome,
  id,
  come,
  onPressCome
}) => {
  if (done)
    return (
      <Typography
        sx={{ margin: 2, alignSelf: "flex-end", color: "rgba(90,160,90,1)" }}
      >
        تم انتهاء الزيارة في
        <br />
        {moment(new Date(doneDate)).locale("en").format("YYYY-MM-DD")} /{" "}
        {moment(new Date(doneDate)).locale("en").format("h:mm A")}{" "}
      </Typography>
    );
  else if (cancel)
    return (
      <Typography
        sx={{ margin: 2, alignSelf: "flex-end", color: "rgba(190,90,90,1)" }}
      >
        الحجز ملغي بتاريخ <br />
        {moment(new Date(cancelDate)).locale("en").format("YYYY-MM-DD")} /{" "}
        {moment(new Date(cancelDate)).locale("en").format("h:mm A")}{" "}
      </Typography>
    );
  else if (didntCome) return null;
  else
    return (
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: 2,
          paddingLeft: 2,
        }}
      >
        <Button
          size="medium"
          variant="outlined"
          color="error"
          onClick={() => setOpenReject(true)}
        >
          إلغاء الحجز
        </Button>
        {confirmed ? (
          <Button
            size="medium"
            variant="outlined"
            color="success"
            onClick={() => !come ? onPressCome() : onDone(id)}
          >
           {!come ?  'متواجد بالعيادة' :  'تمت' } 
          </Button>
        ) : (
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={() =>  onConfirm(id)}
          >
            تأكيد الحجز
          </Button>
        )}
      </CardActions>
    );
};

const PatientCard = ({
  id,
  number,
  name,
  mobile,
  duration,
  visitDate,
  visitType,
  createdBy,
  createdAt,
  come,
  confirmed,
  confirmedBy,
  cancel,
  cancelDate,
  didntCome,
  urgent,
  veryUrgent,
  done,
  doneDate,
  onChangeCome,
  onChangeUrgent,
  onChangeVeryUrgent,
  onDidntCome,
  onConfirm,
  onDone,
  onReject,
  onCancelConfirm,
  onCancelReject,
  updateReservations,
  onMakeItLast,
  index,
  durations,
  durationId,
  waiting,
  showWaiting,
  makeItWaiting,
  confirm_msg,
  queue_msg,
  come_msg,
  queueNumber,
  notes,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [note, setNote] = useState("");
  const [openReserveConsultationDialog, setOpenReserveConsultationDialog] =
    useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorMobile, setAnchorMobile] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const mobileOpen = Boolean(anchorMobile);
  const handleClickMobile = (event) => {
    setAnchorMobile(event.currentTarget);
  };
  const handleCloseMobile = () => {
    setAnchorMobile(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeShowCome = () => {
    onChangeCome(id);
    //setShowIn(!showIn)
    handleClose();
  };
  const onChangeIsUrgent = () => {
    onChangeUrgent(id);
    //setIsUrgent(!isUrgent)
    //setIsVeryUrgent(false)
    handleClose();
  };
  const onChangeIsVeryUrgent = () => {
    onChangeVeryUrgent(id);
    //setIsVeryUrgent(!isVeryUrgent)
    //setIsUrgent(false)
    handleClose();
  };
  const onChangeDidntCome = () => {
    if (!didntCome) {
      onDidntCome(id);
      //setDidtCome(!didntCome)
      handleClose();
    }
  };

  useEffect(() => {
    setNote(notes);
  }, [notes]);

  const onPressW_msg = (type) => {
    let newMsg =
      type === "confirm"
        ? confirm_msg
        : type === "queue"
        ? queue_msg
        : come_msg;
    switch (type) {
      case "confirm":
        newMsg = confirm_msg;
        break;
      case "queue":
        newMsg = queue_msg;
        break;
      default:
        newMsg = come_msg;
        break;
    }

    let s = newMsg
      .replaceAll("#name#", name)
      .replaceAll(
        "#date#",
        `${moment(new Date(visitDate)).format("dddd")} ${new Date(
          visitDate
        ).toLocaleDateString()}`
      )
      .replaceAll("#duration#", duration)
      .replaceAll("#url#", `${window.location.href}status/patient/${mobile}`)
      .replaceAll("#index#", queueNumber);

    const url = `https://wa.me/+2${mobile}?text=${s}`;
    var win = window.open(url, "_blank");
    win.focus();
    handleCloseMobile();
  };
  const onMakeItLastOne = () => {
    onMakeItLast(id);
    handleClose();
  };

  const onNoteSubmit = async (e) => {
    if (e.key === "Enter") {
      let res = await authAxios.post("/reservation/notes", { id, notes: note });
      setSnackOpen(true);
    }
  };

  return (
    <Card sx={{ minWidth: 275, maxWidth: 600, marginBottom: 2, marginLeft: 2, marginRight: 2, marginTop: 2 }}>
      <Snackbar
        color="success"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
        message="تم الحفظ"
        key={"bottomcenter"}
        autoHideDuration={2000}
      />
      <ReserveConsultationDialog
        open={openReserveConsultationDialog}
        setOpen={setOpenReserveConsultationDialog}
        name={name}
        id={id}
        durations={durations}
        duration={durationId}
        visitDate={new Date(visitDate)}
        onReserve={updateReservations}
      />
      <MoveReservationDialog
        open={openDialog}
        setOpen={setOpenDialog}
        name={name}
        id={id}
        durations={durations}
        duration={durationId}
        visitDate={new Date(visitDate)}
        onMoveReservation={updateReservations}
      />
      <EditReservationDataDialog
        id={id}
        name={name}
        mobile={mobile}
        visitType={visitType}
        open={openEdit}
        setOpen={setOpenEdit}
        onSaveReservation={updateReservations}
      />
      <RejectReservationDialog
        open={openReject}
        setOpen={setOpenReject}
        name={name}
        id={id}
        onConfirm={onCancelConfirm}
        index={index}
      />
      <CardContent>
        {didntCome && (
          <Typography
            sx={{
              flex: 1,
              fontSize: 14,
              textAlign: "right",
              marginTop: 0.4,
              color: "red",
              fontWeight: "bold",
            }}
            gutterBottom
          >
            لم يحضر
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <IconButton onClick={() => setOpenEdit(true)}>
              <EditIcon fontSize="small" />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {/* <MenuItem sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}} onClick={()=>setOpenEdit(true)}>
                  <Typography sx={{...styles.itemStyle, marginRight: 1}} gutterBottom>
                  تعديل البيانات
                  </Typography>
                  <EditIcon fontSize='small'/>
              </MenuItem> */}
              {!done && !didntCome && !cancel && (
                <MenuItem onClick={onChangeShowCome}>
                  <Typography
                    sx={{ ...styles.itemStyle, color: "green" }}
                    gutterBottom
                  >
                    متواجد بالعيادة
                  </Typography>
                </MenuItem>
              )}
              {!done && !didntCome && !cancel && (
                <MenuItem onClick={onChangeIsUrgent}>
                  <Typography sx={styles.itemStyle} gutterBottom>
                    مستعجل
                  </Typography>
                </MenuItem>
              )}
              {!done && !didntCome && !cancel && (
                <MenuItem onClick={onChangeIsVeryUrgent}>
                  <Typography sx={styles.itemStyle} gutterBottom>
                    طوارئ
                  </Typography>
                </MenuItem>
              )}
              {confirmed && !done && !didntCome && !cancel && (
                <MenuItem onClick={onChangeDidntCome}>
                  <Typography
                    sx={{ ...styles.itemStyle, color: "red" }}
                    gutterBottom
                  >
                    لم يحضر
                  </Typography>
                </MenuItem>
              )}
              {done && (
                <MenuItem
                  onClick={() => setOpenReserveConsultationDialog(true)}
                >
                  <Typography sx={styles.itemStyle} gutterBottom>
                    حجز استشارة
                  </Typography>
                </MenuItem>
              )}
              {confirmed && !done && !didntCome && !cancel && (
                <MenuItem onClick={onMakeItLastOne}>
                  <Typography sx={styles.itemStyle} gutterBottom>
                    تأجيل لنهاية القائمة
                  </Typography>
                </MenuItem>
              )}

              {(didntCome || cancel || done) && (
                <MenuItem
                  onClick={() => {
                    makeItWaiting(id);
                    handleClose();
                  }}
                >
                  <Typography
                    sx={{ ...styles.itemStyle, color: "green" }}
                    gutterBottom
                  >
                    ارجاع إلي نفس الدور
                  </Typography>
                </MenuItem>
              )}
              {(didntCome || cancel || done) && (
                <MenuItem onClick={onMakeItLastOne}>
                  <Typography sx={styles.itemStyle} gutterBottom>
                    ارجاع إلي آخر قائمة الإنتظار
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <Box>
              <Typography
                sx={{
                  flex: 1,
                  fontSize: 14,
                  textAlign: "right",
                  marginTop: 0.4,
                }}
                color="text.secondary"
                gutterBottom
              >
                {moment(new Date(visitDate)).locale("en").format("YYYY-MM-DD")}{" "}
                / {moment(new Date(visitDate)).locale("ar").format("dddd")}
              </Typography>
              <Typography
                sx={{ flex: 1, fontSize: 14, textAlign: "center" }}
                color="text.secondary"
                gutterBottom
              >
                {duration}
              </Typography>
            </Box>
            <IconButton onClick={() => setOpenDialog(true)}>
              <InsertInvitationTwoToneIcon />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyItems: "center",
              justifyContent: "center",
              marginTop: 2,
            }}
          >
            <Typography
              sx={{ textAlign: "center", color: come ? "green" : "#000", width: '100%' }}
              variant="h5"
              component="div"
            >
              {name}
            </Typography>
            {(urgent || veryUrgent) && (
              <BlinkedBox
                sx={{
                  backgroundColor: urgent ? "orange" : "red",
                  marginLeft: 2,
                  marginTop: 1,
                }}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            id="mobile-button"
            aria-controls={open ? "mobile-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={mobileOpen ? "true" : undefined}
            onClick={handleClickMobile}
          >
            <Typography sx={{ textAlign: "center", marginTop: 1 }}>
              {mobile}
            </Typography>
          </Button>
          <Menu
            id="mobile-button"
            anchorEl={anchorMobile}
            open={mobileOpen}
            onClose={handleCloseMobile}
            MenuListProps={{
              "aria-labelledby": "mobile-button",
            }}
          >
            <MenuItem onClick={()=>window.open('tel:'+mobile)}>
              <Typography
                sx={{ ...styles.itemStyle }}
                variant="button"
                textAlign={"right"}
              >
                اتصال
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => onPressW_msg("confirm")}>
              <Typography
                sx={{ ...styles.itemStyle }}
                variant="button"
                textAlign={"right"}
              >
                رسالة التأكيد
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => onPressW_msg("come")}>
              <Typography
                sx={{ ...styles.itemStyle }}
                variant="button"
                textAlign={"right"}
              >
                رسالة استدعاء
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => onPressW_msg("queue")}>
              <Typography
                sx={{ ...styles.itemStyle }}
                variant="button"
                textAlign={"right"}
              >
                رسالة الدور
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
        <Typography sx={{ textAlign: "center" }} color="text">
          {visitType}
        </Typography>
        {!confirmed || !showWaiting
          ? null
          : queueNumber && (
              <Typography sx={{ textAlign: "center" }}>
                المنتظرين: {queueNumber}
              </Typography>
            )}
        <Typography sx={{ textAlign: "center" }}>
          رقم الحجز: {number}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <TextField
            value={note}
            fullWidth
            variant="standard"
            inputProps={{
              style: { textAlign: "center", color: "darkblue" },
              enterKeyHint: "go",
            }}
            onKeyDown={onNoteSubmit}
            onChange={(e) => setNote(e.target.value)}
          />
        </Box>
      </CardContent>
      <ReservationStatus
        index={index}
        id={id}
        done={done}
        doneDate={doneDate}
        onDone={onDone}
        cancel={cancel}
        cancelDate={cancelDate}
        onConfirm={onConfirm}
        setOpenReject={setOpenReject}
        confirmed={confirmed}
        didntCome={didntCome}
        come={come}
        onPressCome={onChangeShowCome}
      />
      <Typography
        sx={{ textAlign: "center", fontSize: 12 }}
        color="text.secondary"
        gutterBottom
      >
        {moment(new Date(createdAt)).locale("en").format("YYYY-MM-DD")} /{" "}
        {moment(new Date(createdAt)).locale("en").format("h:mm A")}
      </Typography>
      <Typography
        sx={{ textAlign: "center", fontSize: 12 }}
        color="text.secondary"
        gutterBottom
      >
        {createdBy}
      </Typography>
    </Card>
  );
};

const styles = {
  itemStyle: {
    flex: 1,
    fontSize: 14,
    textAlign: "right",
    marginTop: 0.4,
  },
};

export default PatientCard;
