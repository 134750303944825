import axios from "axios";
import io from "socket.io-client";

let Local = "http://192.168.1.95:5000/";
let Online = "https://api.zayedclinic.com";


const isOnline = true;

const URL = isOnline ? Online : Local

export const socket = io(URL, {
  autoConnect: true,
});

export const serverConn = axios.create({
  baseURL: URL,
});

export const getToken = async () => {
  try {
    let jsonUser = localStorage.getItem("user");
    if (jsonUser) {
      const user = await JSON.parse(jsonUser);
      const refreshToken = user.refreshToken;
      const token = await (
        await serverConn.post(`refresh`, { refreshToken })
      ).data.token;
      localStorage.setItem("user", JSON.stringify({ ...user, token }));
      return token;
    }
    localStorage.clear();
    window.location.href = "/login";
    return null;
  } catch (e) {
    localStorage.clear();
    window.location.href = "/login";
    console.log(e);
  }
};

export const authAxios = axios.create({
  baseURL: URL,
});

authAxios.interceptors.request.use(
  async (config) => {
    let jsonUser = localStorage.getItem("user");
    if (jsonUser) {
      let token = await JSON.parse(jsonUser).token;
      config.headers = {
        Authorization: token,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  (response) => response,
  async function (error) {
    try {
      const originalRequest = error.config;
      if (error.response.status === 403) {
        const access_token = await getToken();
        axios.defaults.headers.common["authorization"] = access_token;
        return authAxios(originalRequest);
      }
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      }
      return Promise.reject(error);
    } catch (e) {
      console.log(e);
      return;
    }
  }
);
